import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Grid, NoResult } from './styles';
import HostBlock from '../../molecules/HostBlock';
import { useLangStore, useUiStore } from '../../../store';
import { sanitize } from '../../../helpers/string';
import { useTranslation } from '../../../hooks/TranslationHooks';
import { Label, Span } from '../../atoms/Text';
import { textSizes } from '../../../assets/theme';

const HostsGrid = ({ searchQuery }) => {
  const theme = useUiStore(store => store.theme);
  const lang = useLangStore(store => store.currentLang);
  const noResultString = useTranslation({ key: 'hostSearchNoResult', lang });
  const placeholderImg = theme === 'light' ? '/placeholder_lm.png' : '/placeholder_dm.png';
  const {
    allStrapiHost: { nodes: hosts },
  } = useStaticQuery(
    graphql`
      query hostsGridQuery {
        allStrapiHost(sort: { fields: [Name], order: ASC }) {
          nodes {
            Name
            id
            fields {
              slug
            }
            upcoming_shows {
              title
            }
            image {
              publicURL
            }
          }
        }
      }
    `,
  );

  const sortedHosts = hosts?.sort ? hosts.sort((a, b) => a.Name.localeCompare(b.Name)) : [];
  const filteredHosts = searchQuery
    ? sortedHosts.filter(host => sanitize(host.Name).includes(sanitize(searchQuery)))
    : sortedHosts;

  return filteredHosts?.length ? (
    <Grid>
      {filteredHosts.map(host => {
        const imgSrc = host.image?.publicURL ?? placeholderImg;
        return host.upcoming_shows?.length !== 0 ? (
          <HostBlock name={host.Name} imgSrc={imgSrc} slug={host.fields.slug} key={host.id} />
        ) : null;
      })}
    </Grid>
  ) : (
    <NoResult opacity={0.5} size={textSizes.xl} align="center">
      {noResultString} <Span italic>{`"${searchQuery}"`}</Span>
    </NoResult>
  );
};

HostsGrid.propTypes = {};

export default HostsGrid;
