import React, { useEffect, useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Page from '../../organisms/Page';
import SearchSvg from '../../icons/Search';
import ContentWrapper from '../../Layout/ContentsWrapper';

import HostsGrid from '../../organisms/HostsGrid';
import ArchiveSwitch from '../../molecules/ArchiveSwitch';
import { PaddedRow, SearchInput } from './styles';
import CrossSvg from '../../icons/Cross';
import { useTheme } from 'styled-components';
import { Row } from '../../Layout/Flex';
import { useDeviceStore } from '../../../store';
import { useKey } from 'react-use';

const HostsPage = pageData => {
  const [searchQuery, setSearchQuery] = useState('');
  const theme = useTheme();
  const inputRef = useRef();
  const isMobile = useDeviceStore(store => store.isMobile);

  useKey([], () => {
    inputRef.current.focus();
  });

  const {
    strapiHostPage: { page_meta },
  } = useStaticQuery(
    graphql`
      query hostsPageQuery {
        strapiHostPage {
          page_meta {
            page_title_fr
            page_title_en
          }
        }
      }
    `,
  );

  const clearSearch = () => {
    setSearchQuery('');
  };

  return (
    <Page title={{ en: page_meta.page_title_en, fr: page_meta.page_title_fr }} data={pageData}>
      <ContentWrapper
        css={`
          position: relative;
          padding-bottom: 64px;
        `}
        $paddingTop="0"
      >
        <PaddedRow justify="space-between" align="center">
          <ArchiveSwitch isHosts />
          <Row justify="flex-end" align="center">
            <SearchInput
              onChange={e => setSearchQuery(e.target.value)}
              valueLength={searchQuery.length || 1}
              ref={inputRef}
              value={searchQuery}
            />
            {searchQuery ? (
              <CrossSvg
                size={isMobile ? 18 : 24}
                colour={theme?.foreground}
                weight="light"
                data-hoverable
                onClick={clearSearch}
              />
            ) : (
              <SearchSvg
                size={isMobile ? 18 : 24}
                colour={theme?.foreground}
                data-hoverable
                onClick={() => inputRef.current.focus()}
              />
            )}
          </Row>
        </PaddedRow>
        <HostsGrid searchQuery={searchQuery} />
      </ContentWrapper>
    </Page>
  );
};

export default HostsPage;
