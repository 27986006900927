import React, { useState } from 'react';
import { textSizes } from '../../../assets/theme';
import { useTimeout } from '../../../hooks/TimerHooks';
import Link from '../../atoms/Link';
import { Label } from '../../atoms/Text';
import { Wrapper, ImgBlock, TextBlock } from './styles';

const HostBlock = ({ name, imgSrc, slug }) => {
  const [visible, setVisible] = useState(false);

  useTimeout(() => {
    setVisible(true);
  }, (Math.floor(Math.random() * 100) + 1) * 5);

  return (
    <Link to={slug}>
      <Wrapper visible={visible}>
        <ImgBlock src={imgSrc} />
        <TextBlock>
          <Label size={textSizes.lg} bold>
            {name}
          </Label>
        </TextBlock>
      </Wrapper>
    </Link>
  );
};

HostBlock.propTypes = {};

export default HostBlock;
