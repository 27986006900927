import styled from 'styled-components';
import { spacing } from '../../../assets/theme';

const size = '250px';

export const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.foreground};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;

export const ImgBlock = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.foreground};
  padding-top: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  ${props => props.src && `background-image: url(${props.src})`};
`;

export const TextBlock = styled.div`
  padding: ${spacing[2]}px;
`;
